var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    !_vm.loadingEdit
      ? _c(
          "div",
          { staticClass: "wrapper__card" },
          [
            _c("div", { staticClass: "wrapper__card__header" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        icon: "$settings_rounded",
                        primary: "",
                        small: "",
                        rounded: "",
                        text: "configurar jornada",
                      },
                      on: {
                        click: function ($event) {
                          _vm.configurationDialog = true
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inpt" },
                  [
                    _c(
                      "v-form",
                      { ref: "formname" },
                      [
                        _c("z-input", {
                          attrs: {
                            rules: [(v) => !!v || "Campo obrigatório"],
                            "hide-details": true,
                            label: "Nome da Jornada",
                          },
                          model: {
                            value: _vm.journey.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.journey, "name", $$v)
                            },
                            expression: "journey.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "icons" },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: { disabled: !_vm.isDisabledUndo },
                        on: {
                          click: function ($event) {
                            return _vm.moveStep(false)
                          },
                        },
                      },
                      [_vm._v("$undo_rounded")]
                    ),
                    _c(
                      "v-icon",
                      {
                        attrs: { disabled: !_vm.isDisabledRedo },
                        on: {
                          click: function ($event) {
                            return _vm.moveStep(true)
                          },
                        },
                      },
                      [_vm._v(" $redo_rounded ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "right" }, [
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      staticClass: "mr-2",
                      attrs: {
                        text: "Salvar Jornada",
                        "is-loading": _vm.updateLoading,
                        small: "",
                        rounded: "",
                      },
                      on: { click: _vm.updateJourneyV2 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: { small: "", rounded: "", text: "validar" },
                      on: {
                        click: function ($event) {
                          return _vm.validateJourneySteps(_vm.treeTest)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        icon: "$help_outline_rounded",
                        small: "",
                        rounded: "",
                        text: "ajuda",
                      },
                      on: {
                        click: function ($event) {
                          _vm.helpDialog = true
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            !_vm.isTreeLoading
              ? _c("div", { staticClass: "wrapper__card__body" }, [
                  _c(
                    "div",
                    { staticClass: "wrapper__card__body__content__center" },
                    [
                      _vm.data.nodes.length === 0
                        ? _c(
                            "button",
                            {
                              staticStyle: {
                                "z-index": "999",
                                position: "absolute",
                                left: "40%",
                                cursor: "pointer",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.createDefaultJourney()
                                },
                              },
                            },
                            [_vm._v(" Clique para adicionar uma nova jornada ")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm.treeTest != null
                    ? _c(
                        "div",
                        {
                          staticClass: "wrapper__card__body__content",
                          staticStyle: { "padding-left": "10px" },
                        },
                        [
                          _vm.treeTest != null && _vm.render
                            ? _c("TreeComponent", {
                                key: _vm.keyComponent,
                                attrs: {
                                  "database-config-id-prop":
                                    _vm.databaseConfigId,
                                  nodes: _vm.treeTest,
                                },
                                on: {
                                  linkClick: _vm.clickLink,
                                  yes_click: _vm.yes_click,
                                  no_click: _vm.no_click,
                                  node_click: _vm.nodeClick,
                                  node_click_right: _vm.setNodeToRemove,
                                  rename_node: _vm.setNodeToRename,
                                  joinInformations: _vm.joinInformations,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "wrapper__card__body" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center justify-center",
                      staticStyle: { width: "100%" },
                    },
                    [_c("z-loading")],
                    1
                  ),
                ]),
            _c(
              "v-dialog",
              {
                staticStyle: {
                  "border-radius": "15px !important",
                  "z-index": "10 !important",
                },
                attrs: { width: "520" },
                model: {
                  value: _vm.configurationDialog,
                  callback: function ($$v) {
                    _vm.configurationDialog = $$v
                  },
                  expression: "configurationDialog",
                },
              },
              [
                _vm.configurationDialog
                  ? _c("ConfigurationModal", {
                      attrs: {
                        "timer-infos-prop": _vm.recurrencyConfiguration,
                      },
                      on: { close: _vm.closeConfigurationModal },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: {
                  "border-radius": "15px !important",
                  "z-index": "99999 !important",
                },
                attrs: { width: "400" },
                model: {
                  value: _vm.startJourneyDialog,
                  callback: function ($$v) {
                    _vm.startJourneyDialog = $$v
                  },
                  expression: "startJourneyDialog",
                },
              },
              [
                _vm.startJourneyDialog
                  ? _c("StartJourneyModal", {
                      attrs: {
                        "is-audience-prop": _vm.isAudienceClicked,
                        "father-diamond-clicked":
                          _vm.currentDiamondOrChildDiamondClicked,
                      },
                      on: {
                        close: _vm.closeStartJourneyModal,
                        clicked: _vm.startJourneyClickItem,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: {
                  "border-radius": "15px !important",
                  "z-index": "99999 !important",
                },
                attrs: { width: "400" },
                model: {
                  value: _vm.helpDialog,
                  callback: function ($$v) {
                    _vm.helpDialog = $$v
                  },
                  expression: "helpDialog",
                },
              },
              [
                _vm.helpDialog
                  ? _c("HelpModal", {
                      on: {
                        close: function ($event) {
                          _vm.helpDialog = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: {
                  "border-radius": "15px !important",
                  "z-index": "99999 !important",
                },
                attrs: { width: "400" },
                model: {
                  value: _vm.abtestDialog,
                  callback: function ($$v) {
                    _vm.abtestDialog = $$v
                  },
                  expression: "abtestDialog",
                },
              },
              [
                _vm.abtestDialog
                  ? _c("AbTestModal", {
                      attrs: { clickedNode: _vm.clickedNode },
                      on: { close: _vm.closeAbTestModal },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: {
                  "border-radius": "15px !important",
                  "z-index": "99999 !important",
                },
                attrs: { width: "350" },
                model: {
                  value: _vm.configureAwaitDialog,
                  callback: function ($$v) {
                    _vm.configureAwaitDialog = $$v
                  },
                  expression: "configureAwaitDialog",
                },
              },
              [
                _vm.configureAwaitDialog
                  ? _c("ConfigureAwaitModal", {
                      on: { close: _vm.inserirIntervalo },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { width: "300" },
                model: {
                  value: _vm.datePediodDialog,
                  callback: function ($$v) {
                    _vm.datePediodDialog = $$v
                  },
                  expression: "datePediodDialog",
                },
              },
              [_vm.datePediodDialog ? _c("DatePeriodModal") : _vm._e()],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "1100" },
                model: {
                  value: _vm.selectAudienceDialog,
                  callback: function ($$v) {
                    _vm.selectAudienceDialog = $$v
                  },
                  expression: "selectAudienceDialog",
                },
              },
              [
                _vm.selectAudienceDialog
                  ? _c("SelectAudienceModal", {
                      attrs: {
                        "node-prop": _vm.clickedNode,
                        "audience-id-prop": _vm.journey.audienceId,
                      },
                      on: {
                        close: _vm.closeSelectAudienceModal,
                        editSelectedAudience: _vm.editSelectedAudience,
                        newAudience: _vm.newAudience,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: _vm.templateModalWidth },
                model: {
                  value: _vm.selectTemplateDialog,
                  callback: function ($$v) {
                    _vm.selectTemplateDialog = $$v
                  },
                  expression: "selectTemplateDialog",
                },
              },
              [
                _vm.selectTemplateDialog
                  ? _c("SelectTemplateModal", {
                      attrs: {
                        databaseConfigId: _vm.databaseConfigId,
                        typeTemplate: _vm.typeTemplate,
                        "node-prop": _vm.clickedNode,
                      },
                      on: {
                        close: _vm.closeSelectTemplateModal,
                        changeSizeModal: _vm.changeSizeModal,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: _vm.templateModalWidth },
                model: {
                  value: _vm.selectPushDialog,
                  callback: function ($$v) {
                    _vm.selectPushDialog = $$v
                  },
                  expression: "selectPushDialog",
                },
              },
              [
                _vm.selectPushDialog
                  ? _c("SelectPushModal", {
                      attrs: {
                        "node-prop": _vm.clickedNode,
                        databaseConfigId: _vm.databaseConfigId,
                      },
                      on: {
                        close: _vm.closePushModal,
                        changeSizeModal: _vm.changeSizeModal,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "800" },
                model: {
                  value: _vm.filteredAudienceDialog,
                  callback: function ($$v) {
                    _vm.filteredAudienceDialog = $$v
                  },
                  expression: "filteredAudienceDialog",
                },
              },
              [
                _vm.filteredAudienceDialog
                  ? _c("FilteredAudienceModal", {
                      attrs: { dbConnectionId: _vm.databaseConfigId },
                      on: { close: _vm.closeFilteredAudienceModal },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "500" },
                model: {
                  value: _vm.renameNodeDialog,
                  callback: function ($$v) {
                    _vm.renameNodeDialog = $$v
                  },
                  expression: "renameNodeDialog",
                },
              },
              [
                _vm.renameNodeDialog
                  ? _c("RenameNodeModal", {
                      on: {
                        close: function ($event) {
                          _vm.renameNodeDialog = false
                          _vm.nodeToRename = null
                        },
                        rename: _vm.setNewNodeName,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "500" },
                model: {
                  value: _vm.cofirmationModalDialog,
                  callback: function ($$v) {
                    _vm.cofirmationModalDialog = $$v
                  },
                  expression: "cofirmationModalDialog",
                },
              },
              [
                _vm.cofirmationModalDialog
                  ? _c("ConfirmationLeavePageModal", {
                      on: {
                        close: function ($event) {
                          _vm.cofirmationModalDialog = false
                        },
                        confirmLeave: _vm.closeConfirmationLeavePage,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "500" },
                model: {
                  value: _vm.mensageriaCheckDialog,
                  callback: function ($$v) {
                    _vm.mensageriaCheckDialog = $$v
                  },
                  expression: "mensageriaCheckDialog",
                },
              },
              [
                _vm.mensageriaCheckDialog
                  ? _c("MensageriaCheckModal", {
                      attrs: {
                        "current-node-clicked-prop": _vm.clickedNode,
                        mensageriaSteps: _vm.currentMessageriaStepsItems,
                      },
                      on: { close: _vm.closeMensageriaCheckModal },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "500", persistent: "" },
                model: {
                  value: _vm.divisionChooseDialog,
                  callback: function ($$v) {
                    _vm.divisionChooseDialog = $$v
                  },
                  expression: "divisionChooseDialog",
                },
              },
              [
                _vm.divisionChooseDialog
                  ? _c("DivisionChooseModal", {
                      on: {
                        close: function ($event) {
                          _vm.divisionChooseDialog = false
                        },
                        chooseDivision: _vm.setDivisionTypeInNode,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "500" },
                model: {
                  value: _vm.deleteNodeDialog,
                  callback: function ($$v) {
                    _vm.deleteNodeDialog = $$v
                  },
                  expression: "deleteNodeDialog",
                },
              },
              [
                _vm.deleteNodeDialog
                  ? _c("ConfirmDeleteNodeModal", {
                      on: {
                        close: function ($event) {
                          _vm.deleteNodeDialog = false
                          _vm.nodeToRemove = null
                        },
                        remove: function ($event) {
                          _vm.nodeClickRight(_vm.nodeToRemove)
                          _vm.deleteNodeDialog = false
                          _vm.nodeToRemove = null
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "400" },
                model: {
                  value: _vm.chooseStepToCreateDialog,
                  callback: function ($$v) {
                    _vm.chooseStepToCreateDialog = $$v
                  },
                  expression: "chooseStepToCreateDialog",
                },
              },
              [
                _vm.chooseStepToCreateDialog
                  ? _c("ChooseStepToCreate", {
                      on: {
                        close: function ($event) {
                          _vm.chooseStepToCreateDialog = false
                        },
                        select: _vm.selectStepToJoin,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "500" },
                model: {
                  value: _vm.draftModal,
                  callback: function ($$v) {
                    _vm.draftModal = $$v
                  },
                  expression: "draftModal",
                },
              },
              [
                _vm.draftModal
                  ? _c("ConfirmSaveDraftModal", {
                      attrs: { journey: this.journey },
                      on: {
                        close: function ($event) {
                          _vm.draftModal = false
                        },
                        confirm: _vm.saveDraftJourney,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: "400" },
                model: {
                  value: _vm.showModalIgnoreOptout,
                  callback: function ($$v) {
                    _vm.showModalIgnoreOptout = $$v
                  },
                  expression: "showModalIgnoreOptout",
                },
              },
              [
                _vm.showModalIgnoreOptout
                  ? _c("ConfirmOptoutJourney", {
                      on: {
                        close: function ($event) {
                          _vm.showModalIgnoreOptout = false
                        },
                        confirm: _vm.confirmJourneyWithOptout,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { width: "600" },
                model: {
                  value: _vm.colectionNameDialog,
                  callback: function ($$v) {
                    _vm.colectionNameDialog = $$v
                  },
                  expression: "colectionNameDialog",
                },
              },
              [
                _vm.colectionNameDialog
                  ? _c("ColectionNameModal", {
                      attrs: {
                        "actual-node-prop": _vm.clickedNode,
                        databaseConfigId: _vm.databaseConfigId,
                      },
                      on: { close: _vm.closeColectionModal },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "z-index": "99999 !important" },
                attrs: { width: _vm.templateModalWidth },
                model: {
                  value: _vm.selectWppDialog,
                  callback: function ($$v) {
                    _vm.selectWppDialog = $$v
                  },
                  expression: "selectWppDialog",
                },
              },
              [
                _vm.selectWppDialog
                  ? _c("SelectWppModal", {
                      attrs: {
                        "node-prop": _vm.clickedNode,
                        databaseConfigId: _vm.databaseConfigId,
                      },
                      on: { close: _vm.closeWppModal },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "wrapper__card editLoaderCard" },
          [_c("z-loading")],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }